import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FCM } from '@ionic-native/fcm/ngx';
import { AlertController, MenuController, Platform, PopoverController } from '@ionic/angular';
import { EndPage } from './end/end.page';
import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';
import { SERVER_URL } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  url = SERVER_URL
  logo = '';
  nombre: string = 'no';
  avatar: string = '';
  correo: string = '';
  rol: number = 0;
  version = '';
  iss = 'dev';
  iss2 = '';
  copy = '2023';
  redes: any = []
  navigate = [
    {
      title: 'Home',
      url: '/tabs',
      icon: 'home'
    },
    {
      title: 'Ranking',
      url: '/ranking',
      icon: 'stats-chart'
    },
    /*{
      title: 'Radio Gmovil',
      url: '/radio',
      icon: 'radio'
    },*/
  ];


  constructor(
    private popover: PopoverController,
    private auth: AuthService,
    private platform: Platform,
    private service: DataService,
    private fcm: FCM,
    public alertController: AlertController,
    private router: Router
  ) {
    this.initializeApp()

  }

  initializeApp() {
    //

    this.auth.getObservable().subscribe((data) => {
      console.log('data');
      console.log(data)
      this.nombre = data.nombre;
      this.avatar = data.avatar;
      this.correo = data.correo;
      this.rol = data.rol;
      this.version = data.version;
      this.iss = data.iss;
      this.copy = data.copy;
      this.iss2 = data.iss2;
      this.logo = data.logo;
    })

    this.platform.ready().then(() => {


      this.fcm.onNotification().subscribe(data => {
        if (data.wasTapped) {
          console.log("Received in background");
        } else {
          console.log("Received in foreground");
          console.log(data);
          this.presentAlert(data.body, data.title);
        };
      });

      this.service.redsocial().subscribe(res => {
        this.redes = res
      })

    })
  }
  async presentAlert(message, subHeader) {
    const alert = await this.alertController.create({
      message: message,
      subHeader: subHeader,
      buttons: ['Ok']
    });
    await alert.present();
  }

  irredes(url) {
    window.open(url, '_system')

  }


  logout() {
    this.popover.create({
      component: EndPage,
      backdropDismiss: false,
      showBackdrop: false,
    }).then((popoverElement) => {
      popoverElement.present();
    })
  }
  editProfile() {
    this.router.navigateByUrl('edit-profile');
  }
}
