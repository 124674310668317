import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'resetpass',
    loadChildren: () => import('./resetpass/resetpass.module').then( m => m.ResetpassPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tyc',
    loadChildren: () => import('./tyc/tyc.module').then( m => m.TycPageModule)
  },
  {
    path: 'popover',
    loadChildren: () => import('./popover/popover.module').then( m => m.PopoverPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notificaciones',
    loadChildren: () => import('./notificaciones/notificaciones.module').then( m => m.NotificacionesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notificacion/:id',
    loadChildren: () => import('./notificacion/notificacion.module').then( m => m.NotificacionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./init/init.module').then( m => m.InitPageModule)
  },
  {
    path: 'end',
    loadChildren: () => import('./end/end.module').then( m => m.EndPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'preguntas',
    loadChildren: () => import('./preguntas/preguntas.module').then( m => m.PreguntasPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'infor/:id',
    loadChildren: () => import('./infor/infor.module').then( m => m.InforPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'videos',
    loadChildren: () => import('./videos/videos.module').then( m => m.VideosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'envivos',
    loadChildren: () => import('./envivos/envivos.module').then( m => m.EnvivosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'convenios',
    loadChildren: () => import('./convenios/convenios.module').then( m => m.ConveniosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'convenio/:id',
    loadChildren: () => import('./convenio/convenio.module').then( m => m.ConvenioPageModule),
    canActivate: [AuthGuard]
  },
  
  {
    path: 'avatars',
    loadChildren: () => import('./avatars/avatars.module').then(m => m.AvatarsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./edit-profile/edit-profile.module').then(m => m.EditProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'redencion',
    loadChildren: () => import('./redencion/redencion.module').then(m => m.RedencionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ranking',
    loadChildren: () => import('./ranking/ranking.module').then(m => m.RankingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ayuda',
    loadChildren: () => import('./ayuda/ayuda.module').then(m => m.AyudaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'inquietudes',
    loadChildren: () => import('./inquietudes/inquietudes.module').then(m => m.InquietudesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'producto/:id',
    loadChildren: () => import('./producto/producto.module').then(m => m.ProductoPageModule)
  },
  {
    path: 'bono/:id',
    loadChildren: () => import('./bono/bono.module').then( m => m.BonoPageModule)
  },
  {
    path: 'encuestas',
    loadChildren: () => import('./encuestas/encuestas.module').then( m => m.EncuestasPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'novedad/:id',
    loadChildren: () => import('./novedad/novedad.module').then( m => m.NovedadPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modal',
    loadChildren: () => import('./modal/modal.module').then( m => m.ModalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'campat',
    loadChildren: () => import('./campat/campat.module').then( m => m.CampatPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'eventos',
    loadChildren: () => import('./eventos/eventos.module').then( m => m.EventosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'evento/:id',
    loadChildren: () => import('./evento/evento.module').then( m => m.EventoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'myqr',
    loadChildren: () => import('./myqr/myqr.module').then( m => m.MyqrPageModule)
  },
  {
    path: 'actdat',
    loadChildren: () => import('./actdat/actdat.module').then( m => m.ActdatPageModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'directotrio/:id',
    loadChildren: () => import('./directotrio/directotrio.module').then( m => m.DirectotrioPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'diccionario',
    loadChildren: () => import('./diccionario/diccionario.module').then( m => m.DiccionarioPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'nodo/:id',
    loadChildren: () => import('./nodo/nodo.module').then( m => m.NodoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'videocam/:id',
    loadChildren: () => import('./videocam/videocam.module').then( m => m.VideocamPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'book',
    loadChildren: () => import('./book/book.module').then( m => m.BookPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'producciones/:id',
    loadChildren: () => import('./producciones/producciones.module').then( m => m.ProduccionesPageModule),
    canActivate: [AuthGuard]
  },


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
