import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-time-line',
  templateUrl: './time-line.component.html',
})
export class TimeLineComponent {
  @Input('name') name = "ionic";
  @Input('endIcon') endIcon = "ionic";
  constructor() {

  }

}

@Component({
  selector: 'app-time-line-item',
  template: '<ng-content></ng-content>'
})
export class TimeLineItemComponent{
  constructor(){

  }
}


@Component({
  selector:'app-time-line-time',
  template: '<span>{{time.subtitle}}</span> <span>{{time.title}}</span>'
})
export class TimeLineTimeComponent{
  @Input('time') time = {};
  constructor(){

  }
}