import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModalchildrenPageRoutingModule } from './modalchildren-routing.module';

import { ModalchildrenPage } from './modalchildren.page';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    ModalchildrenPageRoutingModule
  ],
  declarations: [ModalchildrenPage]
})
export class ModalchildrenPageModule {}
