import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PopoverController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AuthService } from '../services/auth.service';

const helper = new JwtHelperService();
const TOKEN_KEY = 'jwt-token';
@Component({
  selector: 'app-end',
  templateUrl: './end.page.html',
  styleUrls: ['./end.page.scss'],
})
export class EndPage implements OnInit {

  constructor(
    private auth: AuthService,
    private storage: Storage, 
    private router: Router,private popover:PopoverController,) { }

  ngOnInit() {
  }
  salir(){
    this.popover.dismiss();
    this.auth.logout()
  }

  ClosePopover(){
    this.popover.dismiss();
  }

}
