import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingController, ModalController, NavController } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-modaladdress',
  templateUrl: './modaladdress.page.html',
  styleUrls: ['./modaladdress.page.scss'],
})
export class ModaladdressPage implements OnInit {
  nom1: any = ['Calle', 'Carrera', 'Av. Calle', 'Av. Carrera', 'Circular', 'Circunvalar', 'Transversal', 'Diagonal', 'Manzana', 'Vía'];
  nompc: any = ['Sur', 'Norte', 'Este', 'Oeste'];
  public onForm: FormGroup;
  constructor(
    public loadingController: LoadingController,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    public modalCtrl: ModalController,
    private router: Router,
    private storage: Storage,
    public navCtrl: NavController,){
   }

  ngOnInit() {
    this.onForm = this.formBuilder.group({
      'num1': ['', Validators.compose([
        Validators.required
      ])],
      'num2': ['', Validators.compose([
        Validators.required
      ])],
      'num3': ['', Validators.compose([
        Validators.required
      ])],
      'numpc1': ['', Validators.compose([
      ])],
      'num4': ['', Validators.compose([
        Validators.required
      ])],
      'numpc2': ['', Validators.compose([])],
      'complemento': ['', Validators.compose([
        Validators.required
      ])],
    })
  }

  saveaddress(){
    let address = this.onForm.value.num1+" "+this.onForm.value.num2+" # "+this.onForm.value.num3+" "+this.onForm.value.numpc1+" - "+this.onForm.value.num4+" "+this.onForm.value.numpc2+" "+this.onForm.value.complemento;
    console.log(address);
    this.dismiss(address);
  }
  dismiss(address) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
   this.modalCtrl.dismiss(address);
  }

}
