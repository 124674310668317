import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { TimeLineComponent } from './time-line.component';
import { TimeLineItemComponentModule,  } from './time-line-item.module';
import { TimeLineTimeComponentModule } from './time-line-time.module';



@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule,
    TimeLineItemComponentModule, TimeLineTimeComponentModule],
  declarations: [TimeLineComponent],
  exports: [TimeLineComponent]
})
export class TimeLineComponentModule {}
