import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, throwError, Subject } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { map, switchMap, take } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt'
import { Storage } from '@ionic/storage';
import { Device } from '@ionic-native/device/ngx';
import { environment, SERVER_URL } from 'src/environments/environment';
const helper = new JwtHelperService();
const TOKEN_KEY = 'jwt-token';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private subject = new Subject<any>();
  url = SERVER_URL+"/services";
  options = {
    headers: {
      "Accept": 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    }
  };
  public user: Observable<any>;
  private userData = new BehaviorSubject(null);
  authState = new BehaviorSubject(false);
  datauser: any = [];
  datavalid: any = [];
  constructor(private storage: Storage,
    private http: HttpClient,
    private platform: Platform,
    private router: Router,
    private device: Device,) {
    this.loadStoredToken(); this.platform.ready().then(() => {
      this.ifLoggedIn();
    });
  }
  publishSomeData(data: any) {
    this.subject.next(data);
  }

  getObservable(): Subject<any> {
    return this.subject;
  }
  loadStoredToken() {
    let platformObs = from(this.platform.ready());

    this.user = platformObs.pipe(
      switchMap(() => {
        return from(this.storage.get(TOKEN_KEY));
      }),
      map(token => {
        if (token) {
          let decoded = helper.decodeToken(token);
          console.log(decoded);
          this.userData.next(decoded);
          return true;
        } else {
          return null;
        }
      })
    );
  }
  ifLoggedIn() {
    this.storage.get('TOKEN_KEY').then((response) => {
      if (response) {
        this.authState.next(true);
      }
    });
  }
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
  login(LoginForm: { username: string, password: string }) {
    let auth = this.http.post(this.url + "/login", "LoginForm=" + JSON.stringify(LoginForm), this.options);
    return auth.pipe(
      take(1),
      map(res => {
        this.datauser = res;
        if (!this.datauser.valid) {
          return this.datauser.msn;
        }
        return this.datauser.res;
      }),
      switchMap(token => {
        let decoded = helper.decodeToken(token);
        this.userData.next(decoded);
        this.authState.next(true);
        let storageObs = from(this.storage.set(TOKEN_KEY, token));
        return storageObs;
      })
    );
  }


  getUser() {
    return this.userData.getValue();
  }

  logout() {
    this.storage.remove(TOKEN_KEY).then(() => {
      this.userData.next(null);
      this.authState.next(false);
      this.router.navigateByUrl('/login');
      this.router.ngOnDestroy();
    });
  }
  isAuthenticated() {
    return this.authState.value;
  }


  getValidForPass(data, val) {
    return this.http.post(this.url + '/validforpass', "data=" + data + "&val=" + val, this.options);
  }

  sendtoken(user, token: string, model: string, version: string, so: string) {
    var rtn = this.http.post(this.url + '/settoken',
      '&token=' + token +
      '&user=' + user +
      '&model=' + model +
      '&version=' + version +
      '&so=' + so
      , this.options);
    return rtn;
  }

  ingreso(user) {
    let auth = this.http.post(this.url + "/ingreso", "user=" + user, this.options);
    return auth.pipe(
      take(1),
      map(res => {
        this.datauser = res;
        return this.datauser.res;
      }),
      switchMap(token => {
        let storageObs = this.storage.set(TOKEN_KEY, token);
        return storageObs;
      })
    );
  }
  updateData(UpdateForm, actdat = false) {

    if (this.getUser()) {
      console.log('una vez')
      let auth = this.http.post(this.url + "/updatedata", "update=" + JSON.stringify(UpdateForm) + '&user=' + this.getUser().usuario.identificacion + "&device=" + JSON.stringify(this.device) + "&actdat=" + actdat, this.options);
      if (actdat) {
        console.log(auth)
        return auth;
      } else {
        return auth.pipe(
          take(1),
          map(res => {
            this.datauser = res;
            if (!this.datauser.valid) {
              return this.datauser.msn;
            }
            
            return this.datauser.res;
          }),
          switchMap(token => {
            let decoded = helper.decodeToken(token);
            this.userData.next(decoded);
            this.authState.next(true);
            let storageObs = from(this.storage.set(TOKEN_KEY, token));
     
            return storageObs;
          })
        );
      }
    }
  }

  actData(UpdateForm) {

    if (this.getUser()) {
      console.log('una vez')
      return  this.http.post(this.url + "/updatedata", "update=" + JSON.stringify(UpdateForm) + '&user=' + this.getUser().usuario.identificacion + "&device=" + JSON.stringify(this.device) + "&actdat=true", this.options);
      
    }
  }
}
