import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule } from '@angular/common/http';

import { FCM } from '@ionic-native/fcm/ngx';
import { Device } from '@ionic-native/device/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { PopoverPageModule } from './popover/popover.module';
import { EndPageModule } from './end/end.module';

import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
import { YoutubeModule } from './pipe/youtube/youtube.module';
import { CalendarModule } from 'ion2-calendar';
import { ModalPageModule } from './modal/modal.module';


import { NgxQRCodeModule } from 'ngx-qrcode2';
import { ModaladdressPageModule } from './modaladdress/modaladdress.module';
import { ModalchildrenPageModule } from './modalchildren/modalchildren.module';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';

import { TimeLineComponentModule } from './components/time-line/time-line.module';





@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    PopoverPageModule,
    EndPageModule,
    YoutubeModule,
    CalendarModule,
    ModalPageModule,
    ModaladdressPageModule,
    ModalchildrenPageModule,
    NgxQRCodeModule,
    TimeLineComponentModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    StreamingMedia,
    FCM,
    Device,
    AppVersion,
    PhotoViewer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule { }

