import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingController, ModalController, NavController } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-modalchildren',
  templateUrl: './modalchildren.page.html',
  styleUrls: ['./modalchildren.page.scss'],
})
export class ModalchildrenPage implements OnInit {
  dataUser: any = [];
  generos: any = ['M', 'F'];
  discapacidades: any = [];
  isLoading = false;
  public onHijoForm: FormGroup;
  constructor(
    public loadingController: LoadingController,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    public modalCtrl: ModalController,
    private router: Router,
    private service: DataService,
    public navCtrl: NavController,) { }

  ngOnInit() {
    this.dataUser = this.auth.getUser();
    this.service.getdiscapacidades().subscribe(data => {
      this.discapacidades = data;
    })
    this.onHijoForm = this.formBuilder.group({
      'identificacion': ['', Validators.compose([
        Validators.required,Validators.maxLength(10),Validators.pattern('^[0-9]+$')
      ])],
      'nombres': ['', Validators.compose([
        Validators.required
      ])],
      'primer_apellido': [this.dataUser.usuario.primer_apellido, Validators.compose([
        Validators.required
      ])],
      'segundo_apellido': ['', Validators.compose([])],
      'fecha_nacimiento': ['', Validators.compose([
        Validators.required
      ])],
      'genero': ['', Validators.compose([
        Validators.required
      ])],
      'discapacidad': [1, Validators.compose([
        Validators.required
      ])],
    })
  }

 

  addhijo() {
    this.present();

    this.service.addhijo(this.onHijoForm.value, this.dataUser.usuario.identificacion).subscribe((data) => {
      let resp: any = data;
      this.dismiss();
      this.modalCtrl.dismiss(resp.valid);
    });
  }

  async present() {
    this.isLoading = true;
    return await this.loadingController.create({
      message: "<img src='assets/gif/logo.gif'/>",
      spinner: null,
      duration: 10000,
      translucent: true,
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }
  async dismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  }

}
