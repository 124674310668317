import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { TimeLineItemComponent } from './time-line.component';
import { TimeLineTimeComponentModule } from './time-line-time.module';



@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule, TimeLineTimeComponentModule],
  declarations: [TimeLineItemComponent],
  exports: [TimeLineItemComponent]
})
export class TimeLineItemComponentModule {}
